import React from 'react';

function Brands() {
  return (
    <section className="brands-crev simple section-padding pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mt-100 order-md-2">
            <div className="row">
              <div className="col-6 item ontop">
                <div className="img">
                  <img src="/light/assets/imgs/brands/01.png" alt="" />
                </div>
                <span className="top-left">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
                <span className="top-right">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
                <span className="bottom-left">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
                <span className="bottom-right">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
              </div>
              <div className="col-6 item">
                <div className="img">
                  <img src="/light/assets/imgs/brands/02.png" alt="" />
                </div>
                <span className="top-right">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1 md-hide">
            <div className="sec-head nocurve">
              <div className="ontop">
                <h6 className="sub-title main-color mb-15">
                  Special Thanks To
                </h6>
                <h3 className="fw-600 text-u ls1">
                  All our <span className="fw-200">partners</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-12 order-md-1">
            <div className="row">
              <div className="col-lg-3 col-6 item empty">
                <div className="text">
                  <h2 className="fz-80">
                    6k<span className="fz-30">+</span>
                  </h2>
                  <h6 className="sub-title">Satisfied Clients</h6>
                </div>
                <span className="bottom-left">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
              </div>
              <div className="col-lg-3 col-6 item">
                <div className="img">
                  <img src="/light/assets/imgs/brands/03.png" alt="" />
                </div>
                <span className="top-right">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
                <span className="bottom-left">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
              </div>
              <div className="col-lg-3 col-6 item ontop">
                <div className="img">
                  <img src="/light/assets/imgs/brands/04.png" alt="" />
                </div>
                <span className="top-left">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
                <span className="top-right">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
                <span className="bottom-left">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
                <span className="bottom-right">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
              </div>
              <div className="col-lg-3 col-6 item">
                <div className="img">
                  <img src="/light/assets/imgs/brands/05.png" alt="" />
                </div>
                <span className="top-right">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
                <span className="bottom-right">
                  <svg
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-23 2xl:w-[3.2rem] h-auto"
                  >
                    <rect
                      y="11"
                      width="23"
                      height="0.671958"
                      fill="white"
                    ></rect>
                    <rect
                      x="12"
                      width="23"
                      height="0.671957"
                      transform="rotate(90 12 0)"
                      fill="white"
                    ></rect>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Brands;
