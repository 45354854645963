import React from 'react';

function Works2() {
  return (
    <div className="section-padding pt-0">
      <div className="container">
        <div className="img">
          <img src="/light/assets/imgs/works/projects/5/5.jpg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Works2;
