import React from 'react';

function Blogs() {
  return (
    <section className="blog-list-half section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="item mb-50">
              <div className="row rest">
                <div className="col-lg-6 col-md-5 img rest">
                  <img
                    src="/light/assets/imgs/blog/b/1.jpg"
                    alt=""
                    className="img-post"
                  />
                  <div className="author d-flex align-items-center">
                    <div>
                      <div className="fit-img icon-img-50 circle">
                        <img src="/light/assets/imgs/blog/author.png" alt="" />
                      </div>
                    </div>
                    <div className="ml-15 fz-14">
                      <div>
                        <span className="opacity-7">Posted by</span>
                        <br />
                        UiCamp
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-7 sub-bg cont valign">
                  <div className="full-width">
                    <div className="tags mb-15">
                      <a href="/light/blog-details">Marketing</a>
                    </div>
                    <h5>
                      <a href="/light/blog-details">
                        Free advertising for your online business.
                      </a>
                    </h5>
                    <span className="date fz-12 ls1 text-u opacity-7 mt-80">
                      August 6, 2022
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="item mb-50">
              <div className="row rest">
                <div className="col-lg-6 col-md-5 img rest">
                  <img
                    src="/light/assets/imgs/blog/b/3.jpg"
                    alt=""
                    className="img-post"
                  />
                  <div className="author d-flex align-items-center">
                    <div>
                      <div className="fit-img icon-img-50 circle">
                        <img src="/light/assets/imgs/blog/author.png" alt="" />
                      </div>
                    </div>
                    <div className="ml-15 fz-14">
                      <div>
                        <span className="opacity-7">Posted by</span>
                        <br />
                        UiCamp
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-7 sub-bg cont valign">
                  <div className="full-width">
                    <div className="tags mb-15">
                      <a href="/light/blog-details">Marketing</a>
                      <a href="/light/blog-details">Design</a>
                    </div>
                    <h5>
                      <a href="/light/blog-details">
                        Business meeting 2023 in San Francisco.
                      </a>
                    </h5>
                    <span className="date fz-12 ls1 text-u opacity-7 mt-80">
                      August 6, 2022
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="item mb-50">
              <div className="row rest">
                <div className="col-lg-6 col-md-5 img rest">
                  <img
                    src="/light/assets/imgs/blog/b/2.jpg"
                    alt=""
                    className="img-post"
                  />
                  <div className="author d-flex align-items-center">
                    <div>
                      <div className="fit-img icon-img-50 circle">
                        <img src="/light/assets/imgs/blog/author.png" alt="" />
                      </div>
                    </div>
                    <div className="ml-15 fz-14">
                      <div>
                        <span className="opacity-7">Posted by</span>
                        <br />
                        UiCamp
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-7 sub-bg cont valign">
                  <div className="full-width">
                    <div className="tags mb-15">
                      <a href="/light/blog-details">Marketing</a>
                      <a href="/light/blog-details">Design</a>
                    </div>
                    <h5>
                      <a href="/light/blog-details">
                        Free advertising for your online business.
                      </a>
                    </h5>
                    <span className="date fz-12 ls1 text-u opacity-7 mt-80">
                      August 6, 2022
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="item mb-50">
              <div className="row rest">
                <div className="col-lg-6 col-md-5 img rest">
                  <img
                    src="/light/assets/imgs/blog/b/4.jpg"
                    alt=""
                    className="img-post"
                  />
                  <div className="author d-flex align-items-center">
                    <div>
                      <div className="fit-img icon-img-50 circle">
                        <img src="/light/assets/imgs/blog/author.png" alt="" />
                      </div>
                    </div>
                    <div className="ml-15 fz-14">
                      <div>
                        <span className="opacity-7">Posted by</span>
                        <br />
                        UiCamp
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-7 sub-bg cont valign">
                  <div className="full-width">
                    <div className="tags mb-15">
                      <a href="/light/blog-details">Design</a>
                    </div>
                    <h5>
                      <a href="/light/blog-details">
                        Business meeting 2023 in San Francisco.
                      </a>
                    </h5>
                    <span className="date fz-12 ls1 text-u opacity-7 mt-80">
                      August 6, 2022
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="item mb-50">
              <div className="row rest">
                <div className="col-lg-6 col-md-5 img rest">
                  <img
                    src="/light/assets/imgs/blog/b/5.jpg"
                    alt=""
                    className="img-post"
                  />
                  <div className="author d-flex align-items-center">
                    <div>
                      <div className="fit-img icon-img-50 circle">
                        <img src="/light/assets/imgs/blog/author.png" alt="" />
                      </div>
                    </div>
                    <div className="ml-15 fz-14">
                      <div>
                        <span className="opacity-7">Posted by</span>
                        <br />
                        UiCamp
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-7 sub-bg cont valign">
                  <div className="full-width">
                    <div className="tags mb-15">
                      <a href="/light/blog-details">Marketing</a>
                      <a href="/light/blog-details">Design</a>
                    </div>
                    <h5>
                      <a href="/light/blog-details">
                        Free advertising for your online business.
                      </a>
                    </h5>
                    <span className="date fz-12 ls1 text-u opacity-7 mt-80">
                      August 6, 2022
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="item mb-50">
              <div className="row rest">
                <div className="col-lg-6 col-md-5 img rest">
                  <img
                    src="/light/assets/imgs/blog/b/6.jpg"
                    alt=""
                    className="img-post"
                  />
                  <div className="author d-flex align-items-center">
                    <div>
                      <div className="fit-img icon-img-50 circle">
                        <img src="/light/assets/imgs/blog/author.png" alt="" />
                      </div>
                    </div>
                    <div className="ml-15 fz-14">
                      <div>
                        <span className="opacity-7">Posted by</span>
                        <br />
                        UiCamp
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-7 sub-bg cont valign">
                  <div className="full-width">
                    <div className="tags mb-15">
                      <a href="/light/blog-details">Marketing</a>
                      <a href="/light/blog-details">Design</a>
                    </div>
                    <h5>
                      <a href="/light/blog-details">
                        Business meeting 2023 in San Francisco.
                      </a>
                    </h5>
                    <span className="date fz-12 ls1 text-u opacity-7 mt-80">
                      August 6, 2022
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
